import { defineStore } from 'pinia'
import { useGeneralStore } from '@/stores/generalStore.js'
import { API } from "aws-amplify";
import { useAuthStore } from '@/stores/auth/authStore';

export const useAccountNotificationStore = defineStore('AccountNotification', {
    dependencies: [
        useGeneralStore
    ],
    state: () => ({
        currentQueueInformationValues: {},
        partnerOptions: [],
        notAllowedArray: ["FirstCall", "Legacy", "PRINCETON", "Sandbox", "SoundCom", "Wholesale", "LogicomUSA"],
        currentInformationType: '',
        currentPartner: ''
    }), 
    getters: {
        getPartnerOptions(state) {
            return state.partnerOptions
        },
        getInformationType(state) {
            return state.currentInformationType
        },
        getPartner(state) {
            return state.currentPartner
        }
    },
    actions: {
        async getSpecificInformation(partner = this.currentPartner, type = this.currentInformationType) {
            const authStore = useAuthStore();
           
            const response = await API.post("psql", '/query', {
                headers: {
                    Authorization: authStore.$state.cognito_tokens.id_token,
                    "Content-Type": "application/json"
                },
                body: {
                    store: "accountNotificationStore/GET",
                    query: "getQueueByID",
                    values: [partner],
                    database: "tickets"
                }
            });

            if (!response.success) {
                return {error: true, message: "Unable to get queue information"}
            }

            if (type === 'contact_information') {
                return {error: false, message: response.data.rows[0].contact_information}
            }
            else if (type === 'overflow_textbox_information') {
                return {error: false, message: response.data.rows[0].overflow_textbox_information}
            }
            else if (type === 'ticket_subject_values') {
                return {error: false, message: response.data.rows[0].ticket_subject_values}
            }
            else if (type === 'tools_list') {
                return {error: false, message: response.data.rows[0].tools_list}
            }
            else {
                return {error: false, message: ''}
            }
        },
        async fetchData() {
            const authStore = useAuthStore();

            const response = await API.post("psql", '/query', {
                headers: {
                    Authorization: authStore.$state.cognito_tokens.id_token,
                    "Content-Type": "application/json"
                },
                body: {
                    store: "accountNotificationStore/GET",
                    query: "getCCQueues",
                    values: [],
                    database: "tickets"
                }
            });

            if (response.success) {
                const queue_options = response.data.rows.map((record) => {
                    return {label: record.queue_name, value: record.id}
                });

                return queue_options;
            }
            else {
                console.log("Error pulling queues: ", response);
                return [];
            }
        },
        async saveData(fields = {}) {

            const authStore = useAuthStore();

            const get_queue_response = await API.post("psql", '/query', {
                headers: {
                    Authorization: authStore.$state.cognito_tokens.id_token,
                    "Content-Type": "application/json"
                },
                body: {
                    store: "accountNotificationStore/GET",
                    query: "getQueueByID",
                    values: [fields.partner],
                    database: "tickets"
                }
            });

            const response = await API.post("psql", '/query', {
                headers: {
                    Authorization: authStore.$state.cognito_tokens.id_token,
                    "Content-Type": "application/json"
                },
                body: {
                    store: "accountNotificationStore/UPDATE",
                    query: "updateQueue",
                    values: [
                        fields?.contact_information || get_queue_response.data.rows[0].contact_information,
                        fields?.overflow_textbox_information || get_queue_response.data.rows[0].overflow_textbox_information,
                        fields?.ticket_subject_values || get_queue_response.data.rows[0].ticket_subject_values,
                        fields?.tools_list || JSON.stringify(get_queue_response.data.rows[0].tools_list),
                        fields.partner
                    ],
                    database: "tickets"
                }
            });

            if (response.success) {
                this.currentQueueInformationValues = this.fetchData();

                return {error: false, message: 'Information Updated'};
            }
            else {
                return {error: true, message: "Unable to update queue information"}
            }
            
        }
    },
})

