<template>
    <div class="df_multiselectcomponent">
        <MultiSelectComponent 
            :label="PROPS.label"
            :options="PROPS.select_options"
            v-bind="{...PROPS.select_props}"
            v-model="form_data_select"
            :no_overflow="true"
        />
    </div>
    <div v-if="form_data_select.length > 0" style="margin-top: 20px;">
        <label class="listLabel">{{ PROPS.list_label }}</label>
    </div>
    <div
      v-for="option of form_data_select"
      :key="option"
      class="dynamicOptionContainer"
    >
        <div class="RemoveContainer" style="width: 10%; margin: auto;">
            <RemoveButton
              @click.prevent="removeFromList(option.value)"
            />
        </div>
        <div style="width: 70%;">
            <MultiSelectComponent 
                v-if="option.field_type.toLowerCase() == 'select' && option.select_options && option.select_options.length > 0"
                :options="option.select_options"
                v-bind="{...option_props}"
                v-model="form_data_filter[option.value]"
                :no_overflow="true"
            />
            <input 
                v-else
                type="text" 
                @input="handleInputChange(option.value, $event)"
                :placeholder="option.label"
                :value="form_data_filter[option.value]"
                class="defaultSize"
            />
        </div>
        <div style="width: 25%;">
            <label class="dynamicOptionLabel">
                {{ option.label }}
            </label>
        </div>
    </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch, computed } from "vue";
import RemoveButton from "@/components/base_components/Buttons/RemoveButton.vue"
import MultiSelectComponent from "@/components/base_components/MultiSelectComponent.vue";

//Constants
const form_data_select = ref([]);
const form_data_filter = ref({});

const emit = defineEmits(['update:modelValue']);

const PROPS = defineProps({
    label: {
        type: String,
        default: ""
    },
    select_placeholder: {
        type: String,
        default: ""
    },
    select_options: {
        type: Array,
        default: () => []
    },
    select_props: {
        type: Object,
        default: () => {}
    },
    list_label: {
        type: String,
        default: ""
    },
    modelValue: {
        type: Object,
        default: () => {}
    }
})

function removeFromList(value) {
    var filtered_options = form_data_select.value.filter(x => x.value != value);
    form_data_select.value = filtered_options;
}

function handleInputChange(option, val) {
    form_data_filter.value[option] = val.target.value; 
    emit("update:modelValue", form_data_filter.value); 
}

watch(form_data_select, 
    (data, old_data) => {
        var new_keys = data.map(x => x.value);
        var old_keys = old_data.map(x => x.value);
        var key_change = "";
        var temp = {...form_data_filter.value};
        if(new_keys.length > old_keys.length) {
            key_change = new_keys.filter(x => !old_keys.includes(x))[0];
            var data_val = data.find(x => x.value == key_change);
            if(Object.keys(PROPS.modelValue).includes(key_change)) {
                temp[key_change] = PROPS.modelValue[key_change];
            } else {
                if(data_val.select_options && Array.isArray(data_val.select_options)) {
                    temp[key_change] = [];
                } else {
                    temp[key_change] = "";
                }
            }
        } else {
            key_change = old_keys.filter(x => !new_keys.includes(x))[0];
            delete temp[key_change];
        }

        form_data_filter.value = temp;
        emit("update:modelValue", form_data_filter.value); 
    }
);

const local_modelValue = computed(() => {
    return PROPS.modelValue;
});

watch(local_modelValue,
    (data, old_data) => {
        var data_string = JSON.stringify(data);
        var old_data_string = JSON.stringify(old_data);

        if(old_data_string != data_string) {
            var keys = Object.keys(data);
            var selectedOptions = PROPS.select_options.filter(x => keys.includes(x.value));
            form_data_select.value = selectedOptions;
            form_data_filter.value = data;
            emit("update:modelValue", form_data_filter.value); 
        }
    }
)

</script>

<style scoped>
.df_multiselectcomponent {
    width: 50%;
    padding-right: 12px;
}

.dynamicOptionLabel {
  margin-left: 10px;
  margin-bottom: 5px;
  display: block;
  font-size: 9pt;
  font-weight: 500;
  color: #271d7a;
}
.dynamicOptionContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 100%;
}
.dynamicOptionsRemove {
  width: 20px;
  height: 15px;
  padding-left: 5px;
  padding-top: 1px;
}
.defaultSize {
    outline: #2e3e91 solid 0.25pt;
    border-radius: 2px;
    padding-left: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 9pt;
    font-weight: 500;
    color: #271d7a;
    background-color: #ffffff;
    height: 28px;
    width: 100%;
}
.listLabel {
    font-size: 9pt;
    font-weight: 600;
    color: #271d7a;
    padding-bottom: 5px;
}
</style>