import { defineStore } from "pinia";
import { useAuthStore } from "@/stores/auth/authStore.js";
import { API } from "aws-amplify";


import * as queries from "@/graphql/queries.js";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";



export const useQubicleRealtimeStore = defineStore("qubicleEvents", {
    state: () => ({
        agents: {},
        sessions: {},
        queues: {},
        executor: null,
        socket: null,
    }),
    getters: {
        getAgents(state) {
            return state.agents;
        },
        getAgent(state, agent) {
            return state.agents[agent];
        },
        getAllQueues() {
            let sorted = Object.values(this.queues).sort((a, b) => {
                return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
            })

            return sorted;

        },
        getQueue(state, queue) {
            return state.queues[queue];
        },
        getAgentList(state) {
            return state.agents;
        }
    },
    actions: {
        closeSocket() {
            try {
                clearInterval(this.executor);
                this.executor = null;
                this.socket.close();
                this.socket = null;
            } catch (e) {
                console.error(e);
            }
        },
        /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
         *
         * Entrypoint to store which kicks of store hydration and kicks of background task agent
         *
         * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * **/
        async init() {
            const authStore = useAuthStore();
            const hasCCMgmtPermission = authStore.getUsersApplications.find((app) => app.name === "Management" && app.application_section === "Contact Center") === undefined ? false : true;

            if (authStore.validateAdminStatus() && hasCCMgmtPermission) {
                const hydration_min = 3;

                await this.hydrateAgentProfiles()
                    .then(() => this.hydrateAgentPresence())
                    .then(() => this.hydrateAgentStatus())
                    .then(() => this.hydrateQueues())
                    .then(() => this.hydrateQueueStatus())
                    // Any async state intialization operations should be before websocket initialization
                    .then(() => this.initializeAgentSocket())
                    .then((socket) => {
                        this.socket = socket;
                    })
                    .then(() => { this.hydrateAgentSupervisor() })
                    .then(() => this.hydrateTickets())
                    .then(() => this.getAgentOutboundCalls())
                    .then(() => this.stayHydrated(hydration_min));
            }
        },
        /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
         *
         * Background utility
         *
         * TODO: replace with pub-sub logic when/where applicable
         *
         * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * **/
        async stayHydrated(minutes) {
            const timeout_ms = minutes * 60_000;

            const executor = async () => {
                await this.hydrateTickets()
                .then(() => this.getAgentOutboundCalls());
            }

            this.executor = setInterval(executor, timeout_ms);
        },
        normalizeShortStatus(status) {
            switch (status.toLowerCase()) {
                case "ready":
                    return "available";
                case "away":
                    return "away";
                default:
                    return "on-a-call";
            }
        },
        /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
         *
         * Utility function to normalize agent status to conform to design guidelines and honor supplementary Away status data
         *
         * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * **/
        normalizeLongStatus(status, reason) {
            let match = status.toLowerCase()
            console.debug("STATUS: ", match, reason);
            switch (true) {
                case match == "away" && reason != undefined && reason != "":
                    return `Away - ${reason}`;
                case match == "ready":
                    return "Available";
                case match == "away":
                    return "Away";
                case match == "on-external-call":
                    return "On A Call - Outbound";
                case match == "on-a-call":
                    return "On A Call - Inbound";
                default:
                    return status;
            }
        },
        toHHMMSS(secs) {
            var sec_num = parseInt(secs, 10);
            var hours = Math.floor(sec_num / 3600);
            var minutes = Math.floor(sec_num / 60) % 60;
            var seconds = sec_num % 60;

            return [hours, minutes, seconds]
                .map(v => v < 10 ? "0" + v : v)
                .filter((v, i) => v !== "00" || i > 0)
                .join(":");
        },
        calculateTimeInStatus(timestamp) {
            var gregorian_offset = 62167219200;
            return Math.floor(Date.now() / 1000) - (timestamp - gregorian_offset);
        },
        calculateAgentStates(agents) {
            var acc = { ready: 0, away: 0, on_a_call: 0 };

            if (agents.length > 0) {
                agents.map((id) => {
                    if (id in this.sessions) {
                        switch (this.sessions[id].status.short) {
                            case "away":
                                acc = { ready: acc.ready, away: acc.away + 1, on_a_call: acc.on_a_call };
                                break;
                            case "available":
                                acc = { ready: acc.ready + 1, away: acc.away, on_a_call: acc.on_a_call };
                                break;
                            case "on-a-call":
                                acc = { ready: acc.ready, away: acc.away, on_a_call: acc.on_a_call + 1 };
                                break;
                            default:
                                break
                        }
                    }
                });
            }

            return acc;
        },
        async hydrateAgentPresence() {
            var hasData = true;
            var nextToken = null;

            try {
                while (hasData) {
                    var options = {
                        query: queries.listSwitchUsers,
                        variables: {
                            limit: 1000,
                            filter: {
                                account_id: {
                                    eq: "519b73ddf6c2ba5688acc12e9d0c01d4" //Contact Center
                                },
                            }
                        },
                        authMode: GRAPHQL_AUTH_MODE.API_KEY
                    };

                    if (nextToken != null) {
                        options.variables.nextToken = nextToken;
                    }

                    var users = await API.graphql(options);

                    if (users.data.listSwitchUsers.nextToken != null) {
                        nextToken = users.data.listSwitchUsers.nextToken;
                    } else {
                        hasData = false;
                    }

                    for (const item of users.data.listSwitchUsers.items) {
                        if (this.agents[item.user_id]) {
                            this.agents[item.user_id].profile['presence_id'] = item.presence_id;
                        }
                    }
                }
            } catch (e) {
                console.error(e);
            }
        },
        async getAgentOutboundCalls() {
            try {
                var rightNow = new Date();

                // TODO: this defaults to machine local time and should be fixec to 00:00:00 America/Chicago
                var startOfDay = new Date(rightNow.getFullYear(), rightNow.getMonth(), rightNow.getDate(), 0, 0, 0);
                startOfDay.setHours(0, 0, 0, 0);

                var api_name = "switch";

                const authStore = useAuthStore();

                var userAuth = `Bearer ${authStore.$state.cognito_tokens.id_token}`;
                var gregorian_offset = 62167219200;
                let created_from = startOfDay.getTime() / 1000 + gregorian_offset;
                let created_to = startOfDay.getTime() / 1000 + 86400 + gregorian_offset;

                var myInit = {
                    headers: {
                        Authorization: userAuth,
                        "Content-Type": "application/json",
                    },
                    params: {},
                }
                var hasData = true;

                var queue_acc = {};
                var agent_acc = {};
                Object.keys(this.queues).map((id) => queue_acc[id] = 0 );
                Object.keys(this.sessions).map((id) => agent_acc[id] = 0 );
                while (hasData) {
                    let params = new URLSearchParams(myInit.params).toString();
                    var path = `/accounts_frontend/519b73ddf6c2ba5688acc12e9d0c01d4/cdrs/interaction?${params}`;

                    var result_json = await API.get(api_name, path, myInit);
                    var agent_calls = result_json.data.data;

                    for (var call of agent_calls) {
                        if ((call.timestamp >= created_from || call.timestamp >= created_to) && call.dialed_number[0] == "*") {
                            if (this.sessions[call.owner_id] != undefined) {
                                agent_acc[call.owner_id] += 1;
                            }

                            switch(call.dialed_number.slice(0,3)) {
                                case "*17":
                                    //ACE         *17
                                    queue_acc["0a23e9066899471bdc08fe5503932e93"] += 1;
                                    break;
                                case "*14":
                                    //Central Access	*14
                                    queue_acc["c1502ff578b3bf52f1d300014c704498"] += 1;
                                    break;
                                case "*62":
                                    // Coosa Valley	*62
                                    queue_acc["9f86d78cb574ddc052360d206e6abcac"] += 1;
                                    break;
                                case "*49":
                                    // Flash Fiber	*49
                                    queue_acc["830d69266fefd7962a72441b01ab9e8b"] += 1;
                                    break;
                                case "*25":
                                    // Freedom Fiber	*25
                                    queue_acc["a5b64a060fb053e9dd9b9aa795861d52"] += 1;
                                    break;
                                case "*56":
                                    // Hope Water and Light	*56
                                    queue_acc["9b5f9308493ed9437f2bf1fbb4028224"] += 1;
                                    break;
                                case "*65":
                                    // IQ Fiber	*65
                                    queue_acc["b974f286172a5bdebb0c3d5f1a066cb6"] += 1;
                                    break;
                                case "*27":
                                    // ML Connect	*27
                                    queue_acc["d6d266dc1cdbd9cfa56c2b6ccd39bf08"] += 1;
                                    break;
                                case "*28":
                                    // NAEC	*28
                                queue_acc["23f0500a55f8e291354bba0249c62d6d"] += 1;
                                    break;
                                case "*61":
                                    // NTSpark	*61
                                    queue_acc["830324ec6cccb8c337351ffd332765a8"] += 1;
                                    break;
                                case "*51":
                                    // Phirelink	*51
                                    queue_acc["910a366d2fcfc183f2c8a0b4ca4049c4"] += 1;
                                    break;
                                case "*16":
                                    // Prentiss Connect	*16
                                    queue_acc["4efb6be94eff9075e022641ee7860bf2"] += 1;
                                    break;
                                case "*18":
                                    // RiverNet Connect	*18
                                    queue_acc["b9325fadae8b6ed22d0993032c1d210a"] += 1;
                                    break;
                                case "*42":
                                    // Singing River Connect	*42
                                queue_acc["5bd4a870b6c33411e072a4c822fb329e"] += 1;
                                    break;
                                case "*19":
                                    // Sprout Fiber	*19
                                    queue_acc["6a6a63dc4d8fd29b797093499a873f5d"] += 1;
                                    break;
                                case "*60":
                                    // Tishomingo Connect	*60
                                    queue_acc["c4f7dcad20a51d0c00ca03035114b848"] += 1;
                                    break;
                                case "":
                                    // TVI Fiber	*15
                                    queue_acc["da086a2cd01fafb1f1e7fdd85dba5ec3"] += 1;
                                    break;
                                case "*70":
                                    // Retail	*70
                                    queue_acc["c01b7e07e4ab1480a45cb7d8abd4f1d7"] +=1;
                                    break;
                                case "*52":
                                    // Tombigbee EPA Tupleo "bf9c47960c4405efebdc2ba5b8f36a55"
                                    queue_acc["bf9c47960c4405efebdc2ba5b8f36a55"] += 1;
                                    // Tombigbee FULton "f160caac7d47904af905df4d2ea3f07a"
                                    queue_acc["f160caac7d47904af905df4d2ea3f07a"] += 1;
                                    break;
                                case "199":
                                    // TODO: Determine mapping(s)
                                    // Supervisor Contact	199
                                    // this.queues[""].stats.outbound_calls += 1;
                                    break;
                                case "*26":
                                    // Sequatchie "aba88768b78e71024bf2129e96618817" *26
                                    queue_acc["aba88768b78e71024bf2129e96618817"] += 1;
                                    break;
                                case "*53":
                                    // KUB "0605654387ec5ba30a38bf1a01f4ac58"   *53
                                    queue_acc["0605654387ec5ba30a38bf1a01f4ac58"] += 1;
                                    break;
                                default:

                                    break;
                            }
                        } else if (call.timestamp < created_from || call.timestamp > created_to) {
                            hasData = false;
                            break;
                        }
                    }

                    // Update pagination key if next_start_key defined otherwise bail out
                    if (result_json.data.next_start_key != 'undefined') {
                        myInit.params.start_key = result_json.data.next_start_key;
                    }
                    else {
                        hasData = false;
                    }
                }

                Object.keys(queue_acc).map((id) => this.queues[id].stats.outbound_calls = queue_acc[id]);
                Object.keys(agent_acc).map((id) => this.sessions[id].stats.outbound_calls = agent_acc[id]);
            } catch (e) {
                console.log(e)
            }
        },
        async hydrateAgentProfiles() {
            var api_name = "switch";
            var path = `/accounts_frontend/519b73ddf6c2ba5688acc12e9d0c01d4/qubicle_recipients`;

            const authStore = useAuthStore();

            var userAuth = `Bearer ${authStore.$state.cognito_tokens.id_token}`;

            var myInit = {
                headers: {
                    Authorization: userAuth,
                    "Content-Type": "application/json"
                },
            }
            try {
                let result_json = await API.get(api_name, path, myInit);

                var agents = result_json.data.data;

                agents.map((agent) => {
                    this.agents[agent.id] = {
                        profile: {
                            display_name: agent.display_name,
                            name: agent.name.replace("1@", "@"),    // Remove 1 on switch user email
                        }
                    };
                });
            } catch (e) {
                console.error(e);
            }
        },
        async hydrateAgentStatus() {
            let api_name = "switch";
            let path = `/accounts_frontend/519b73ddf6c2ba5688acc12e9d0c01d4/qubicle_recipients/status`;


            const authStore = useAuthStore();

            let userAuth = `Bearer ${authStore.$state.cognito_tokens.id_token}`;
            let agent_ids = Object.keys(this.agents);

            var myInit = {
                headers: {
                    Authorization: userAuth,
                    "Content-Type": "application/json"
                },
                body: {
                    data: {
                        recipient_ids: agent_ids
                    }
                }
            }
            try {
                var result_json = await API.post(api_name, path, myInit);
                var agent_statuses = result_json.data.data;

                Object.keys(agent_statuses).map((id) => {
                    if (agent_statuses[id] != "not_logged_in") {
                        let caller_id = null;
                        if (agent_statuses[id].handling_call) {
                            caller_id = {
                                number: agent_statuses[id].handling_call.caller_id_num,
                                name: agent_statuses[id].handling_call.caller_id_name,
                                queue: agent_statuses[id].handling_call.queue_name,
                            }
                        }
                        this.sessions[id] = {
                            id: id,
                            supervisor: "N/A",
                            login_time: agent_statuses[id].stats.login_time,
                            status: {
                                short: this.normalizeShortStatus(agent_statuses[id].availability_state),
                                first: this.normalizeLongStatus(agent_statuses[id].availability_state, agent_statuses[id].away_reason),
                                second: this.calculateTimeInStatus(agent_statuses[id].stats.last_action_time),
                                caller_id: caller_id,
                            },
                            profile: {
                                name: this.agents[id].profile.name,
                                display_name: this.agents[id].profile.display_name,
                                presence_id: this.agents[id].profile.presence_id,
                            },
                            tickets: {
                                count: 0,
                                created: 0,
                                resolved: 0,
                                escalated: 0,
                            },
                            stats: {
                                outbound_calls: 0,
                                callsCurrentShift: agent_statuses[id].stats.offered_calls,
                                answered_calls: agent_statuses[id].stats.total_calls,
                                missed_calls: agent_statuses[id].stats.missed_calls,
                                abandoned_calls: (agent_statuses[id].stats.offered_calls - (agent_statuses[id].stats.total_calls + agent_statuses[id].stats.missed_calls))
                            },
                            metrics: {},
                        };
                    }
                })
            } catch (e) {
                console.error(e);
            }

        },
        async hydrateAgentSupervisor() {
            var options = {
                query: queries.listEmployees,
                variables: {
                    filter: {
                        _deleted: { ne: true },
                        assigned_group: { eq: "Contact Center" }
                    },
                    limit: 1000
                },
                authMode: GRAPHQL_AUTH_MODE.API_KEY
            };

            try {
                var employees = await API.graphql(options);
                var employeeMap = {};

                Object.keys(this.agents).map((id) => {
                    let normalized_name = "";
                    this.agents[id].profile.display_name.split('-').length > 1
                        ? normalized_name = this.agents[id].profile.display_name.toLowerCase().split('-')[1].split(' ').join(' ')
                        : normalized_name = this.agents[id].profile.display_name.toLowerCase().split(' ').join(' ');

                    employeeMap[normalized_name] = id;
                });

                for (const employee of employees.data.listEmployees.items) {
                    let normalized_name = employee.name.toLowerCase().split(' ').join(' ');
                    let id = employeeMap[normalized_name];

                    if (this.sessions[id] != undefined) {
                        this.sessions[id].supervisor = employee.supervisor || "N/A";
                    }
                }
            } catch (e) {
                console.error(e);
            }
        },
        async hydrateTickets() {
            var rightNow = new Date();
            const authStore = useAuthStore();

            // TODO: this defaults to machine local time and should be fixec to 00:00:00 America/Chicago
            var startOfDay = new Date(rightNow.getFullYear(), rightNow.getMonth(), rightNow.getDate(), 0, 0, 0);
            startOfDay.setHours(0, 0, 0, 0);

            var agents_acc = {};
            var queues_acc = {};

            const queue_response = await API.post("psql", '/query', {
                headers: {
                    Authorization: authStore.$state.cognito_tokens.id_token,
                    "Content-Type": "application/json"
                },
                body: {
                    store: "qubicleRealTimeStore/GET",
                    query: "getAllQueues",
                    values: [],
                    database: "tickets"
                }
            });

            let queue_map = {};
            queue_response.data.rows.map((queue) => {
                /*
                 *   Storage Format: {lex_queue_id: kazoo_queue_id}
                 *
                 *   ** NOTE ** this could contain an array for specific cases (i.e. Tombigbee)
                 */
                const empty_queue = { callbacks_scheduled: 0, dispatched: 0, deleted: 0, new: 0, opened: 0, resolved: 0, internal_internet_escalations: 0, external_internet_escalations: 0, open_csr_escalations: 0, logicom: 0, overflow: 0 };

                if (queue.switch_queue_name && queue.switch_queue_name.split(',').length > 1) {
                    let queues = queue.switch_queue_name.split(",");
                    queues.map((id) => {
                        queues_acc[id] = empty_queue;
                    });

                    queue_map[queue.id] = queues;
                } else if (queue.switch_queue_name) {
                    queues_acc[queue.switch_queue_name] = empty_queue;
                    queue_map[queue.id] = queue.switch_queue_name;
                }
            });


            try {
                const tickets_response = await API.post("psql", '/query', {
                    headers: {
                        Authorization: authStore.$state.cognito_tokens.id_token,
                        "Content-Type": "application/json"
                    },
                    body: {
                        store: "qubicleRealTimeStore/GET",
                        query: "getTodaysTickets",
                        values: [
                            startOfDay.toISOString(),
                            rightNow.toISOString()
                        ],
                        database: "tickets"
                    }
                });


                let emailMap = {};
                Object.keys(this.sessions).map((id) => {
                    agents_acc[id] = { count: 0, created: 0, resolved: 0, escalated: 0 };
                    emailMap[this.sessions[id].profile.name] = id;
                })

                tickets_response.data.rows.map((ticket_object) => {

                    const ticket = ticket_object.ticket;
                    let call_center_agent = null;

                    if (ticket.custom_fields) {
                        call_center_agent = ticket?.custom_fields.find((custom_field) => custom_field?.queue_custom_field?.field_name == "Call Center Agent")?.set_value;
                    }

                    let creator_id = emailMap[ticket.creator];
                    let owner_id = emailMap[ticket.owner];
                    let queue_id = null;
                    let status = ticket.status.toLowerCase();

                    //LINE BELOW - Become Depracated once were off RT
                    if (!this.sessions[creator_id]) { creator_id = emailMap[call_center_agent];} 

                    queue_id = queue_map[ticket.queue.id];
                    // Hydrate agent totals
                    switch(true) {
                        case (this.sessions[creator_id] != null || this.sessions[creator_id] != undefined) && (creator_id == owner_id):
                            // Agent is ticket creator and owner
                            agents_acc[creator_id].count += 1;
                            agents_acc[creator_id].created += 1;

                            // Update Agent tickets resolved stat
                            if (ticket.status == "Resolved") {
                                agents_acc[creator_id].resolved += 1;
                            }

                            break;
                        case this.sessions[creator_id] != null || this.sessions[creator_id] != undefined:
                            // Agent is ticket creator
                            agents_acc[creator_id].count += 1;
                            agents_acc[creator_id].created += 1;

                            // Update Agent tickets resolved stat
                            if (ticket.status == "Resolved") {
                                agents_acc[creator_id].resolved += 1;
                            }
                            break;
                        case this.sessions[owner_id] != null || this.sessions[owner_id] != undefined:
                            // Agent is ticket creator
                            agents_acc[owner_id].count += 1;

                            if (ticket.status == "Resolved") {
                                agents_acc[owner_id].resolved += 1;
                            }
                            break;
                        default:
                            break;
                    }

                    if (this.queues[queue_id] && typeof (queue_id) === "string") {
                        queues_acc[queue_id][status] += 1;
                    } else if (this.queues[queue_id] && typeof (queue_id) === "object") {
                        // Update stats if queue_id is an array, i.e. Tombigbee
                        queue_id.map((id) => {
                            queues_acc[id][status] += 1;
                        })
                    }

                    // Accumulate logicom tickets
                    if (this.queues[queue_id] && ticket.owner.includes("logicomusa")) {
                        queues_acc[queue_id].logicom += 1;
                    }
                    // Accumuilate Overflow tickets
                    else if (this.queues[queue_id] && ticket.owner.includes("ptelco")) {
                        queues_acc[queue_id].overflow += 1;
                    }

                    var custom_fields = ticket.custom_fields;

                    // Queue Ticket Escalation Logic
                    if (custom_fields) {
                        for (var custom_field of custom_fields) {
                            if (this.queues[queue_id] && custom_field?.queue_custom_field?.field_name == "Escalation" && (custom_field.set_value.trim().includes("External Standard"))) {
                                queues_acc[queue_id].external_internet_escalations += 1;
                                if (((this.sessions[creator_id] != null || this.sessions[creator_id] != undefined) || (this.sessions[owner_id] != null || this.sessions[owner_id] != undefined)) && owner_id == creator_id) {
                                    agents_acc[creator_id].escalated += 1;
                                } else if (this.sessions[creator_id] != null || this.sessions[creator_id] != undefined) {
                                    agents_acc[creator_id].escalated += 1;
                                } else if (this.sessions[owner_id] != null || this.sessions[owner_id] != undefined) {
                                    agents_acc[owner_id].escalated += 1;
                                }

                            } else if (this.queues[queue_id] && custom_field?.queue_custom_field?.field_name == "Escalation" && (custom_field.set_value.trim().includes("External Unsupported"))) {
                                queues_acc[queue_id].open_csr_escalations += 1
                                if (((this.sessions[creator_id] != null || this.sessions[creator_id] != undefined) || (this.sessions[owner_id] != null || this.sessions[owner_id] != undefined)) && owner_id == creator_id) {
                                    agents_acc[creator_id].escalated += 1;
                                } else if (this.sessions[creator_id] != null || this.sessions[creator_id] != undefined) {
                                    agents_acc[creator_id].escalated += 1;
                                } else if (this.sessions[owner_id] != null || this.sessions[owner_id] != undefined) {
                                    agents_acc[owner_id].escalated += 1;
                                }
                            }
                            else if (this.queues[queue_id] && custom_field?.queue_custom_field?.field_name == "Escalation" && (custom_field.set_value.includes("Internal"))) {
                                queues_acc[queue_id].internal_internet_escalations += 1;
                                if (((this.sessions[creator_id] != null || this.sessions[creator_id] != undefined) || (this.sessions[owner_id] != null || this.sessions[owner_id] != undefined)) && owner_id == creator_id) {
                                    agents_acc[creator_id].escalated += 1;
                                } else if (this.sessions[creator_id] != null || this.sessions[creator_id] != undefined) {
                                    agents_acc[creator_id].escalated += 1;
                                } else if (this.sessions[owner_id] != null || this.sessions[owner_id] != undefined) {
                                    agents_acc[owner_id].escalated += 1;
                                }
                            }
                            if (this.queues[queue_id] && ticket.status != 'Resolved' && custom_field?.queue_custom_field?.field_name == "Callback" && custom_field.set_value != "" && typeof (queue_id) === "string") {
                                queues_acc[queue_id].callbacks_scheduled += 1;
                            } else if (this.queues[queue_id] && ticket.status != 'Resolved' && custom_field?.queue_custom_field?.field_name == "Callback" && custom_field.set_value != "" && typeof (queue_id) === "object") {
                                {
                                    queue_id.map((id) => {
                                        queues_acc[id].callbacks_scheduled += 1;
                                    })
                                }
                            }
                        }
                    }
                });
                

                // push new stats objects
                Object.keys(agents_acc).map((id) => {
                    this.sessions[id].tickets = agents_acc[id];
                });
                Object.keys(queues_acc).map((id) => {
                    if (this.queues[id]) {
                        this.queues[id].tickets = queues_acc[id];
                    }
                });
            } catch (e) {
                console.error(e);
            }
        },
        async initializeAgentSocket() {
            const api_name = "switch";
            const path = `/get_auth_token`;

            const authStore = useAuthStore();

            const userAuth = `Bearer ${authStore.$state.cognito_tokens.id_token}`;

            const myInit = {
                headers: {
                    Authorization: userAuth,
                    "Content-Type": "application/json"
                },
                body: {}
            };

            try {
                let result_json = await API.put(api_name, path, myInit);

                const authStore = useAuthStore();
                const websocket_url = authStore.getSwitchWebSocketUrl;

                var AgentSocket = new WebSocket(websocket_url);
                // _self.socket = new WebSocket(websocket_url);

                //TODO: Make Socket Connection
                AgentSocket.onopen = function () {
                    //A subscription must include a valid pair of account_id / auth_token, and must include the binding that you want to listen to.
                    var recipientSubscription = {
                        action: 'subscribe',
                        auth_token: result_json.data.auth_token,
                        data: {
                            account_id: '519b73ddf6c2ba5688acc12e9d0c01d4',
                            bindings: [
                                'qubicle.recipient',
                                'qubicle.queue',
                                'dashboard.callcenter_pro'
                            ],
                        }
                    };

                    //bindings we might want: qubicle.recipient, dashboard.callcenter_pro, qubicle.queue
                    //The data flowing through sockets must be a String, so we cast the JSON into a String
                    var recipientSubscriptionString = JSON.stringify(recipientSubscription);
                    //Once we properly configured our subscription, we can send the corresponding string to the WebSocket, which will notify the system that we want to subscribe to an event
                    AgentSocket.send(recipientSubscriptionString);
                };

                AgentSocket.onmessage = (msg) => {
                    let eventObj = JSON.parse(msg.data)

                    switch (eventObj.action) {
                        case "reply":
                            break;
                        case "event":
                            this.handleQubicleEvent(eventObj);
                            break;
                        default:
                            break;
                    }
                };
                return AgentSocket;
            } catch (e) {
                console.error(e);
            }
        },
        async handleAgentLogin(obj) {
            this.sessions[obj.data.recipient_id] = {
                status: {
                    short: this.normalizeShortStatus(obj.data.state),
                    first: this.normalizeLongStatus(obj.data.state, obj.data.reason),
                    second: this.calculateTimeInStatus(obj.data.event_timestamp)
                },
                supervisor: "N/A",
                profile: {
                    display_name: this.agents[obj.data.recipient_id].profile.display_name,
                    name: this.agents[obj.data.recipient_id].profile.name,
                },
                tickets: {
                    count: 0,
                    created: 0,
                    resolved: 0,
                    escalated: 0,
                },
                stats: {
                    outbound_calls: 0,
                    callsCurrentShift: obj.data.stats.offered_calls,
                    answered_calls: obj.data.stats.total_calls,
                    missed_calls: obj.data.stats.missed_calls,
                    abandoned_calls: (obj.data.stats.offered_calls - (obj.data.stats.total_calls + obj.data.stats.missed_calls))
                },
                metrics: {},
            };

            await this.hydrateAgentSupervisor();
        },
        async handleAgentLogout(obj) {
            delete this.sessions[obj.data.recipient_id];
        },
        async handleQubicleEvent(obj) {
            try {
                switch (true) {
                    case obj.subscribed_key == "qubicle.recipient" && obj.name == "sync":
                        this.handleAgentSyncEvent(obj);
                        break;
                    case obj.subscribed_key == "qubicle.recipient":
                        this.handleAgentStatusEvent(obj);
                        break;
                    case obj.subscribed_key == "qubicle.queue":
                        this.handleQueueStatusEvent(obj);
                        break;
                    case obj.subscribed_key == "dashboard.callcenter_pro":
                        this.handleTabulatorEvent(obj);
                        break;
                    default:
                        break;
                }
            } catch (error) {
                console.error(error);
            }
        },
        async handleAgentStatusEvent(obj) {
            try {
                var callType = "";
                switch (obj.name) {
                    case "away":
                        this.sessions[obj.data.recipient_id].status = {
                            short: this.normalizeShortStatus(obj.data.state),
                            first: this.normalizeLongStatus(obj.data.state, obj.data.away_reason),
                            second: this.calculateTimeInStatus(obj.data.event_timestamp)
                        };
                        break;
                    case "delivered":
                        callType = "Inbound";

                        this.sessions[obj.data.recipient_id].status = {
                            short: "on-a-call",
                            first: `On A Call - ${callType}`,
                            second: this.calculateTimeInStatus(obj.data.event_timestamp),
                            caller_id: {
                                name: obj.data.caller_id_name ? obj.data.caller_id_name : null,
                                number: obj.data.caller_id_num ? obj.data.caller_id_num : null,
                                queue: obj.data.queue_name ? obj.data.queue_name : null
                            }
                        };
                        break;
                    case "external_call_start":
                        callType = "Outbound";
                        this.sessions[obj.data.recipient_id].status =
                        {
                            short: "on-a-call",
                            first: `On A Call - ${callType}`,
                            second: this.calculateTimeInStatus(obj.data.event_timestamp),
                            caller_id: {
                                name: obj.data.external_call ? obj.data.caller_id_name : null,
                                number: obj.data.external_call ? obj.data.caller_id_num : null,
                                queue: obj.data.queue_name ? obj.data.queue_name : null
                            }
                        };
                        break;
                    case "hangup":
                        this.sessions[obj.data.recipient_id].status =
                        {
                            short: this.normalizeShortStatus(obj.data.state),
                            first: this.normalizeLongStatus(obj.data.state, obj.data.away_reason),
                            second: this.calculateTimeInStatus(obj.data.event_timestamp),
                            caller_id: null,
                        };
                        break;

                    // TODO: re-enable this case once better understand dropped hangup event
                    // case "external_call_end":
                    //     this.sessions[obj.data.recipient_id].status =
                    //     {
                    //         short: "available",
                    //         first: "Available",
                    //         second: this.calculateTimeInStatus(obj.data.event_timestamp),
                    //         caller_id: null,
                    //     };
                    //     break;
                    case "hold":
                        this.sessions[obj.data.recipient_id].status =
                        {
                            short: "on-a-call",
                            first: `Hold`,
                            second: this.calculateTimeInStatus(obj.data.event_timestamp)
                        };
                        break;
                    case "create":
                        this.handleAgentLogin(obj);
                        break;
                    case "delete":
                        this.handleAgentLogout(obj);
                        return;
                    case "offer":
                        this.sessions[obj.data.recipient_id].status =
                        {
                            short: "available",
                            first: "Call Offered",
                            second: this.calculateTimeInStatus(obj.data.event_timestamp),
                            caller_id: {
                                name: obj.data.caller_id_name ? obj.data.caller_id_name : null,
                                number: obj.data.caller_id_num ? obj.data.caller_id_num : null,
                                queue: obj.data.queue_name ? obj.data.queue_name : null,
                            }
                        };
                        break;
                    case "wrapup_start":
                        this.sessions[obj.data.recipient_id].status = {
                            short: "on-a-call",
                            first: "Recovery",
                            second: this.calculateTimeInStatus(obj.data.event_timestamp),
                            caller_id: null,
                        };
                        break;
                    // COMMENTED OUT as wiping out away reason now after hangup sets it
                    // case "wrapup_complete":
                    //     console.debug("STATUS EVENT: ", obj.data)
                    //     this.sessions[obj.data.recipient_id].status = {
                    //         short: this.normalizeShortStatus(obj.data.state),
                    //         first: this.normalizeLongStatus(obj.data.state, obj.data.away_reason),
                    //         second: this.calculateTimeInStatus(obj.data.event_timestamp),
                    //     };
                    //     break;
                    case "ready":
                        this.sessions[obj.data.recipient_id].status = {
                            short: "available",
                            first: "Available",
                            second: this.calculateTimeInStatus(obj.data.event_timestamp),
                        };
                        break;
                    default:
                        break;
                }
            } catch (e) {
                console.error(e);
            }
        },
        async handleAgentSyncEvent(obj) {
            try {
                this.sessions[obj.data.recipient_id]['stats'].callsCurrentShift = obj.data.stats.stats.global.call_count;
                this.sessions[obj.data.recipient_id]['stats'].answered_calls = obj.data.stats.stats.global.call_count - obj.data.stats.stats.global.missed_call_count;
                this.sessions[obj.data.recipient_id]['stats'].missed_calls = obj.data.stats.stats.global.missed_call_count;
                this.sessions[obj.data.recipient_id]['stats'].abandoned_calls =obj.data.stats.stats.global.rejected_call_count;

                this.sessions[obj.data.recipient_id]['status'].second = this.calculateTimeInStatus(obj.data.last_action_time);
            } catch (e) {
                console.error(e);
            }
        },
        async handleQueueStatusEvent(obj) {
            switch (obj.name) {
                case "create":
                    this.queues[obj.data.queue_id].stats = {
                        estimated_wait: obj.data.stats.estimated_wait,
                        average_wait: obj.data.stats.average_wait,
                        recipient_count: obj.data.stats.recipient_count,
                        active_session_count: obj.data.stats.active_session_count,
                        total_sessions: obj.data.stats.total_sessions,
                        missed_sessions: obj.data.stats.missed_sessions,
                        abandoned_sessions: obj.data.stats.abandoned_sessions
                    };

                    break;
                case "delete":
                    try {
                        delete this.queues[obj.queue_id];
                    } catch (error) {
                        console.error(`error removing ${obj.queue_id}`);
                    }
                    break;
                case "add_member":
                    break;
                case "remove_member":
                    break;
                case "sync":
                    var statuses = this.calculateAgentStates(obj.data.members);

                    this.queues[obj.data.queue_id].stats.available_recipient_count = obj.data.available_recipient_count;
                    this.queues[obj.data.queue_id].stats.active_recipient_count = obj.data.active_recipient_count;
                    this.queues[obj.data.queue_id].stats.estimated_wait = this.toHHMMSS(obj.data.stats.estimated_wait);
                    this.queues[obj.data.queue_id].stats.average_wait = this.toHHMMSS(obj.data.stats.average_wait);
                    this.queues[obj.data.queue_id].stats.recipient_count = obj.data.stats.recipient_count;
                    this.queues[obj.data.queue_id].stats.active_session_count = obj.data.stats.active_session_count;
                    this.queues[obj.data.queue_id].stats.total_sessions = obj.data.stats.total_sessions;
                    this.queues[obj.data.queue_id].stats.missed_sessions = obj.data.stats.missed_sessions;
                    this.queues[obj.data.queue_id].stats.abandoned_sessions = obj.data.stats.abandoned_sessions
                    this.queues[obj.data.queue_id].members = obj.data.members;
                    this.queues[obj.data.queue_id].stats.ready = statuses.ready;
                    this.queues[obj.data.queue_id].stats.away = statuses.away;
                    this.queues[obj.data.queue_id].stats.on_a_call = statuses.on_a_call;

                    this.queues[obj.data.queue_id].stats['missed_calls'] = obj.data.stats.missed_sessions;
                    this.queues[obj.data.queue_id].stats['abandoned_calls'] = obj.data.stats.abandoned_sessions;
                    this.queues[obj.data.queue_id].stats['answered_calls'] = obj.data.stats.total_sessions - (obj.data.stats.missed_sessions + obj.data.stats.abandoned_sessions);
                    this.queues[obj.data.queue_id].stats['overflow_calls'] = 0;
                    this.queues[obj.data.queue_id].stats['inbound_calls'] = obj.data.stats.total_sessions;

                    break;
                default:
                    break;
            }
        },
        async hydrateQueues() {
            //Pull back initial Switch queues
            var api_name = "switch";
            var path = `/accounts_frontend/519b73ddf6c2ba5688acc12e9d0c01d4/qubicle_queues`;

            const authStore = useAuthStore();

            var userAuth = `Bearer ${authStore.$state.cognito_tokens.id_token}`;

            var myInit = {
                headers: {
                    Authorization: userAuth,
                    "Content-Type": "application/json"
                },
            };

            try {
                let result_json = await API.get(api_name, path, myInit);
                var queues = result_json.data.data;

                queues.map(async (queue) => {
                    this.queues[queue.id] = {
                        id: queue.id,
                        name: queue.name,
                        members: [],
                        stats: {},
                        tickets: { callbacks_scheduled: 0, dispatched: 0, deleted: 0, new: 0, opened: 0, resolved: 0, internal_internet_escalations: 0, external_internet_escalations: 0, open_csr_escalations: 0, logicom: 0, overflow: 0 }
                    }
                });
            } catch (e) {
                console.error(e);
            }

        },
        async hydrateQueueStatus() {
            await Promise.all(Object.keys(this.queues).map(async (id) => {
                var api_name = "switch";
                var path = `/accounts_frontend/519b73ddf6c2ba5688acc12e9d0c01d4/qubicle_queues/${id}/status`;

                const authStore = useAuthStore();

                var userAuth = `Bearer ${authStore.$state.cognito_tokens.id_token}`;

                var myInit = {
                    headers: {
                        Authorization: userAuth,
                        "Content-Type": "application/json",
                    },
                };

                try {
                    let result_json = await API.get(api_name, path, myInit);

                    let obj = result_json.data;

                    this.queues[id].stats['abandoned_sessions'] = obj.data.stats.abandoned_sessions;
                    this.queues[id].stats['active_session_count'] = obj.data.stats.active_session_count;
                    this.queues[id].stats['average_wait'] = this.toHHMMSS(obj.data.stats.average_wait);
                    this.queues[id].stats['estimated_wait'] = this.toHHMMSS(obj.data.stats.estimated_wait);
                    this.queues[id].stats['longest_wait'] = this.toHHMMSS(obj.data.stats.longest_wait);
                    this.queues[id].stats['missed_sessions'] = obj.data.stats.missed_sessions;
                    this.queues[id].stats['active_recipient_count'] = obj.data.active_recipient_count;
                    this.queues[id].stats['available_recipient_count'] = obj.data.available_recipient_count;
                    this.queues[id].stats['recipient_count'] = obj.data.stats.recepient_count;
                    this.queues[id].stats['total_sessions'] = obj.data.stats.total_sessions;
                    this.queues[id].stats.ready = obj.data.available_recipient_count;
                    this.queues[id].stats.away = 0;
                    this.queues[id].stats.on_a_call = obj.data.active_recipient_count - obj.data.available_recipient_count;
                    this.queues[id].stats['missed_calls'] = obj.data.stats.missed_sessions;
                    this.queues[id].stats['abandoned_calls'] = obj.data.stats.abandoned_sessions;
                    this.queues[id].stats['answered_calls'] = obj.data.stats.total_sessions - (obj.data.stats.missed_sessions + obj.data.stats.abandoned_sessions)
                    this.queues[id].stats['overflow_calls'] = 0;
                    this.queues[id].stats['inbound_calls'] = obj.data.stats.total_sessions;
                    this.queues[id].stats['outbound_calls'] = 0;
                    this.queues[id].stats['calls_in_queue'] = 0;
                    this.queues[id].stats['active_calls'] = 0;
                } catch (e) {
                    console.error(e);
                }

            }));


            // TODO: this is a todo list, will remove this once feature parity with legacy dashboard

            // var outboundCallsCount = await this.getQueueOutboundCalls(queue.name.trim());
            // var callsHandedToOverflowCount = await this.getQueueOverflowCalls(queue.name.trim());

            //         inboundAndOutboundCalls: { first: result_json.data.data.stats.total_sessions, second: outboundCallsCount }, //Not Valid
            //         missedAndAbandonedCalls: { first: result_json.data.data.stats.missed_sessions, second: result_json.data.data.stats.abandoned_sessions }, //Valid Data
            //         callsHandedToOverflow: callsHandedToOverflowCount, //Not Valid
            //         logicomUSAAndOverflowTickets: { first: `${queueTicketInformation.logicomTickets}%`, second: `${queueTicketInformation.overflowTickets}` },
            //         "Average Recovery Time": `00:16`, //dont know how to get
            //         current_queue_sessions: [],
            //     }

        },
        // TODO: Remove commented code later after testing/refactors complete
        handleTabulatorEvent(obj) {
            switch (true) {
                case obj.name == "update" && obj.data.recipients != undefined:
                    // obj.data.recipients.map((record) => {
                    //     let id = Object.keys(record)[0]
                    //     console.log("RECIPIENT_STATE", record[id], Object.keys(record[id]));

                    //     switch(true) {
                    //         // maybe re-hydrate active queue
                    //         case record[id].active_queue_name != "" && record[id].active_queue_name != undefined:
                    //             if (this.sessions[id] && this.sessions[id].status.caller_id != undefined || this.sessions[id].status.caller_id != null) {
                    //                 console.log("RECIPIENT_STATE", "rehydrating active queue", this.sessions[id].profile.display_name)
                    //                 this.sessions[id].status.caller_id = {
                    //                     name: null,
                    //                     id_number: null,
                    //                     queue: record[id].active_queue_.name,
                    //                 }
                    //             } else {
                    //                 console.log("RECIPIENT_STATE", "updating active queue", this.sessions[id].profile.display_name)
                    //                  this.sessions[id].status.caller_id.queue = record[id].active_queue.name;
                    //             }
                    //             console.log("RECIPIENT_STATE", "after update", this.sessions[id]);
                    //             console.log("RECIPIENT_STATE", "active_queue", this.agents[id].profile.display_name, record[id].active_queue);
                    //             break;
                    //         default:
                    //             break;
                    //     }
                    // })
                    break;
                case obj.name == "update" && obj.data.queues != undefined:
                    obj.data.queues.map((queue) => {
                        let id = Object.keys(queue)[0];
                        // console.log("QUEUES", "DATA", Object.keys(queue[id]));
                        switch (true) {
                            case queue[id].calls_in_queue != undefined:
                                this.queues[id].stats.calls_in_queue = queue[id].calls_in_queue;
                                break;
                            case queue[id].active_calls != undefined:
                                this.queues[id].stats.active_calls = Object.keys(queue[id].active_calls).length;
                                // this.queues[id].sessions = queue[id].active_calls;
                                break;
                            case queue[id].calls != undefined:
                                break;
                            case queue[id].events != undefined:
                                queue[id].events.map((event) => {
                                    switch (event.event) {
                                        // case "hangup":
                                        //     this.queues[id].stats.active_calls -= 1;
                                        //     // this.queues[id].sessions = queue[id].active_calls;
                                        //     break;
                                        default:
                                            // console.log("QUEUES", "DATA", event.event, event);
                                            break;
                                    }
                                })
                                break;
                            default:
                                break;
                        }
                    })
                    break;
                default:
                    break;
            }
        },
        resetQubicleState() {
            this.agents = {};
            this.sessions = {};
            this.queues = {};
        }
    }
});