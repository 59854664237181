<template>
    <div class="accNotBackground" :style="{ height: dynamicHeight + 'px' }">
    <div class="accNotContainer">
        <div class="accNotContentContainer">
            <div class="accNotPageContent">
                <div class="accNotFormContainer">
                    <FormComp
                    :form-data="formData"
                    :buttonOptions="{
                        positionBottom: true,
                        centered: false,
                        fillToParent: false,
                        divider: true
                    }"
                    :numCols="12"
                    @submissionDataUpdated="handleDropdownUpdates"
                    @submission-data="handleFormSubmission"
                    ></FormComp>
                </div>
            </div>
        </div>
    </div>
    <AlertsGroup
      v-model:alerts="alerts"
    />
  </div>
</template>  

<script>
import FormComp from "@/components/ContactCenterManagement/AccountNotifications/FormComp.vue";
import AlertsGroup from "@/components/base_components/AlertsGroup.vue";
import { addAlert } from '@/helpers/alertUtils';
import { ERROR, SUCCESS } from "@/constants/statuses.constants.js";

import { ref } from 'vue';

import { useAccountNotificationStore } from "@/stores/ContactCenter/Management/AccountNotificationStore.js";
import { mapActions, mapStores } from 'pinia';


export default {
    name: "AccountNotificationView",
    components: {
        FormComp,
        AlertsGroup,
    },
    data() {
        return {
        windowHeight: null,
        showTable: false,
        alerts: [],
        previewContainerHTML: ref(),
        filterOptionsArray: [],
        Partner: '',
        InformationType: '',
        selectedInformation: '',
        htmlData: '',
        showEmail: false,
        formData: [
            { title: "Account Notification - Textbox", type: "form", button: "Update" },
            {
                title: "",
                inputs: [
                    { name: "Partner", type: "select", options: [], required: true, fullWidth: true },
                    { 
                        name: "Information Type", 
                        type: "select",
                        options: [
                            {label: "Contact Information", value: "contact_information"},
                            {label: "Overflow Information", value: "overflow_textbox_information"},
                            {label: "Subject Values", value: "ticket_subject_values"}
                        ],
                        required: true,
                        fullWidth: true
                    },
                    { name: "Information", type: "textbox", defaultColor: "#000000", showColorWheel: false, required: false }
                ],
            },
        ],
        data: [],
        };
    },
    watch: {
        windowHeight() {
            this.$nextTick(() => {
                this.dynamicHeight;
            });
        }
    },
    mounted() {
        window.addEventListener("resize", this.handleResize);
        this.windowHeight = window.innerHeight;

        this.fetchPartnerOptions();

    },
    beforeUnmount() {
        window.removeEventListener("resize", this.handleResize);
    },
    computed: {
        ...mapStores(useAccountNotificationStore),
        dynamicHeight() {
            return Number(this.windowHeight) - 93;
        },
        getNavButtons() {
            if (this.showTable) {
                return [
                { title: "Email", icon: "" },
                { title: "Export", icon: "" },
                ];
            }
            return [];
        },
        getDrawerData() {
            return this.emailData;
        },
    },
    methods: {
        ...mapActions(useAccountNotificationStore, ['fetchData', 'saveData']),
        async handleDropdownUpdates(data) {
            var hasPartner = Object.prototype.hasOwnProperty.call(data, 'Partner');
            var hasInfoType = Object.prototype.hasOwnProperty.call(data, 'Information Type');
            if(hasPartner && hasInfoType && data["Partner"] !== '' && data["Information Type"] !== '') {
                const store = useAccountNotificationStore();
                store.currentPartner = data["Partner"].value;
                store.currentInformationType = data["Information Type"].value;
            }
        },
        async fetchPartnerOptions() {
            var result = await this.AccountNotificationStore.fetchData();
            this.formData[1].inputs[0].options = result;
        },
        handleResize() {
        this.windowHeight = window.innerHeight;
        },
        handleCloseClick() {
        this.showEmail = false;
        },
        async handleFormSubmission(formData) {
            if (formData === "Error" || Object.keys(formData).length === 0) {                
                addAlert(ERROR, 'Please fill in all values', this.alerts);
            } else {
                var fields = {
                    'partner': formData["Partner"].value,
                    [formData["Information Type"].value]: formData["Information"]
                }

                var result = await this.saveData(fields);

                if(result.error) {      
                    addAlert(ERROR, result.message, this.alerts);
                } else {
                    addAlert(SUCCESS, "Successfully Updated Information!", this.alerts);
                }
            }
        }
    },
}

</script>
  
  
<style scoped>
html {
    overflow-y: none;
}

.accNotContainer {
    padding: 20px;
    background-color: #e2f1f4;
    max-width: 75%;
    display: flex;
    width: 85%;
    padding-left: 40px;
  }

  @media (max-width: 1500px) {
      .accNotContainer {
          max-width: none;
      }
  }

  @media (max-width: 1200px) {
    .accNotContainer {
        width: 100%;
    }
    .previewContainer {
        width: 290px;
    }
  }
html {
  overflow-y: auto !important;
}
.accNotBackground {
  background-color: #e2f1f4;
  max-height: 100%;
}
.accNotPageContent, .previewPageContent {
  flex-grow: 1;
}

.accNotPageContent {
  margin-right:20px;
}

.previewPageContent {
    min-width: 100px;
    filter: drop-shadow(1px 1px 1px rgb(39, 29, 122, 0.2));
    max-width: 80%;
}
.accNotContentContainer, .previewContainer {
  display: flex;
  flex-grow: 1;
  position: relative;
  min-height: 580px;
  height: 620px;
  max-height: 100%;
}
.accNotFormContainer {
  /* margin: 20px 0px 20px 0px; */
  filter: drop-shadow(1px 1px 1px rgb(39, 29, 122, 0.2));
}

.previewBoxContainer {
    margin: 20px 0px 20px 0px;
}
.configAlerts {
  position: fixed;
  right: 0;
  padding-bottom: 10px;
  padding-right: 20px;
  z-index: 99;
}

.previewContainer {
  background-color: #ffffff;
  font-family: "Red Hat Display", sans-serif;
  /* min-height: 620px; */
  /* margin: 20px 0px 20px 0px; */
  height: 100%;
  justify-content: center;
  max-width: 500px;
}

.previewHeader {
  /* padding-top: 5px; */
  padding-left: 35px;
  padding-bottom: 10px;
  font-size: 14pt;
  font-weight: bold;
  color: #271d7a;
}

.previewContainerHTML {
    display: flex;
    justify-content: center;
}

.previewContainer .dropdown {
    width: 95%
}
</style>