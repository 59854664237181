<template>
  <div class="tools-container">
    <div class="form-container">
      <ToolsFormComp
        :formData="formData"
        title="Add Device"
        :num-cols="6"
        :button-options="{
          divider: true,
          fillToParent: false
        }"
        @submission-data="handleFormSubmission"
        @submission-data-independent="handleAddTool"
        @dropdown-data="handleDropdownData"
        ref="toolsFormComp"
      >
        <template v-slot:button="slotProps">
          <MediumBlueButton
            class="add-tool-button"
            @click.prevent="slotProps.sendSubmissionData"
          >
            Add Tool
          </MediumBlueButton>

        </template>
        <template>
          <div class="submit-container">
            <div class="submit-left-container">
              <DarkButton> Update Partner Tools </DarkButton>
            </div>
          </div>
        </template>
      </ToolsFormComp>
    </div>

    <div class="list-container">
      <DraggableListComp
        :headers="listHeaders"
        :items="listItems"
        name="Added Tools"
        @remove-item="handleRemoveItem"
        is-deletable
        error-message="No tools yet"
        @update-list-order="updateListOrder"
      />
    </div>
  </div>
  <AlertsGroup
    v-model:alerts="alerts"
  />
</template>

<script>
import ToolsFormComp from "./ToolsFormComp.vue"
// import FormComp from "@/components/base_components/FormComponents/FormComp.vue";
import DarkButton from "@/components/base_components/Buttons/DarkButton.vue";
import MediumBlueButton from "@/components/base_components/Buttons/MediumBlueButton.vue";
import DraggableListComp from "@/components/ContactCenterManagement/AccountNotifications/ToolsEditor/DraggableListComp.vue";
import AlertsGroup from "@/components/base_components/AlertsGroup.vue";
import { addAlert } from '@/helpers/alertUtils';
import { ERROR, SUCCESS } from "@/constants/statuses.constants.js";

import { useAccountNotificationStore } from "@/stores/ContactCenter/Management/AccountNotificationStore.js";
import { mapActions, mapStores } from 'pinia';

export default {
  name: "ToolsEditorView",
  components: {
    ToolsFormComp,
    DarkButton,
    AlertsGroup,
    MediumBlueButton,
    DraggableListComp,
  },
  props: {
    partnerItems: Array,
  },
  data() {
    return {
      alerts: [],
      currentSelectedPartner: null,
      submissionData: {},
      formData: [
        { title: "Account Notification - Tools", type: "form", button: "Update Tools" },
        {
          sectionName: "Partner",
          inputs: [
            {
              name: "Partner",
              type: "select",
              options: [],
              required: true,
            },
          ],
        },
        {
          sectionName: "NewToolName",
          title: "New Tool",
          button: "Add Tool",
          inputs: [
            { name: "name", type: "bubble" },
            { name: "path", type: "bubble" },
            { type: "button" },
          ],
        },
      ],
      listHeaders: ["name", "path"],
      listItems: [],
    };
  },
  mounted() {
      window.addEventListener("resize", this.handleResize);
      this.windowHeight = window.innerHeight;
      this.fetchPartnerOptions();

  },
  beforeUnmount() {
      window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    ...mapStores(useAccountNotificationStore),
      dynamicHeight() {
          return Number(this.windowHeight) - 90;
      },
  },
  methods: {
    ...mapActions(useAccountNotificationStore, ['fetchData', 'saveData']),
    async fetchPartnerOptions() {
        var result = await this.AccountNotificationStore.fetchData();
        this.formData[1].inputs[0].options = result;
    },
    async handleFormSubmission(data) {
      if (data === "Error") {
        addAlert(ERROR, "Please fill out all required fields", this.alerts);
      } else {

        var selectedTools = this.listItems;

        for(let tool of selectedTools){
          tool.button = "medium";
        }

        var fields = {
          'partner': data["Partner"].value,
          'tools_list': JSON.stringify(selectedTools)
        }

        var result = await this.saveData(fields);

        if(result.error) {
          addAlert(ERROR, result.message, this.alerts);
        } else {
          addAlert(SUCCESS, "Successfully Updated Information!", this.alerts);
        }
      }
    },
    handleRemoveItem(item) {
      this.listItems = this.listItems.filter((i) => {
        if (i !== item) {
          return i;
        }
      });
    },
    handleDropdownData(dropdownData) {
      if (dropdownData.name === "Partner") {
        this.currentSelectedPartner = dropdownData.value.value;
      }
    },
    updateListOrder(newList) {
      this.listItems = [...newList];
    },
    handleAddTool(submissionData) {
      if (!submissionData["name"] || !submissionData["path"]) {
        addAlert(ERROR, "Please enter a Label and A URL", this.alerts);
        return;
      }
      //Check if tool with same title already exists

      for(var item of this.listItems) {
        if(item.name == submissionData["name"]) {
          item.path = submissionData["path"];
          return;
        }
      }

      const newTool = {
        "name": submissionData["name"],
        "path": submissionData["path"]
      };

      this.listItems.push(newTool);

      this.$refs.toolsFormComp.updateInputVal(
        {
          name: 'name',
        },
        {
          target: {
            value: "",
          },
        }
      );
      
      this.$refs.toolsFormComp.updateInputVal(
        {
          name: 'path',
        },
        {
          target: {
            value: "",
          },
        }
      );

      addAlert(SUCCESS, "Tool was added", this.alerts);

    },
  },
  watch: {
    async currentSelectedPartner(newPartner) {




      const store = useAccountNotificationStore();
      if (newPartner) {
        var result = await store.getSpecificInformation(newPartner, 'tools_list');

        if(result.error) {
            addAlert(ERROR, result.message, this.alerts);
        } else {
          if(result.message && result.message != '') {
            var toolsObj = result.message;
            this.listItems = toolsObj;
          } else {
            this.listItems = [];
          }
        }
        
      }
    }
  },
};
</script>

<style scoped>
.tools-container {
  display: flex;
  max-width: calc(100vw - 334px);
  padding-bottom: 32px;
}
.form-container,
.list-container {
  margin: 2rem 0 0 2rem;
  flex: 1;
  height: fit-content;
  box-shadow: 0 2px 4px rgba(57, 111, 186, 0.6);
  width: 40%;
  position: relative;
}

.form-container {
  min-height: 400px;
}

@media (max-width: 1250px) {
  .form-container,
  .list-container {
    width: 90%;
    margin: 2rem;
  }
}

@media (max-width: 880px) {
  .tools-container {
    flex-direction: column;
  }
}

.submit-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding: 0px 40px;
}

.submit-left-container {
  display: flex;
  gap: 20px;
}

.add-tool-button {
  max-width: fit-content;
}
</style>
