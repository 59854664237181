<template>
    <v-select ref="searchableSelect" :options="sortedOptions" :multiple="multiple" :disabled="disabled" :clearable="clearable"
        :searchable="searchable" :filterable="filterable" :placeholder="placeholder" :value="value" label="label"
        :select-on-tab="false" @option:selected="handleSelected">
    </v-select>
</template>


<script setup>
import { defineProps, defineEmits, defineExpose, computed } from "vue";

const emit = defineEmits(["option-selected"]);

defineExpose({
    clearSelection
});


const props = defineProps({
    options: Array,
    name: String,
    multiple: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    clearable: {
        type: Boolean,
        default: false,
    },
    searchable: {
        type: Boolean,
        default: true,
    },
    filterable: {
        type: Boolean,
        default: true,
    },
    placeholder: {
        type: String,
        default: ""
    },
    value: {
        default: null
    }
});

const sortedOptions = computed(() => {
    if (props.options && Array.isArray(props.options)) {
        return [...props.options].sort((a, b) => a.label.localeCompare(b.label));
    }
    return props.options;
});

function handleSelected(option) {
    emit("option-selected", props.name, option, 'searchSelect');
}

function clearSelection() {
    this.$refs.searchableSelect.clearSelection()
}
</script>



<style scoped>
@import "vue-select/dist/vue-select.css";

.v-select {
    background-color: white;
    font-size: 12px;

    color: #271d7a;
    --vs-selected-color: #271d7a;


    --vs-font-size: 12px;


    --vs-dropdown-option--active-bg: #eff4f9;
    --vs-dropdown-option--active-color: #271d7a;


    /* border-radius: 1px; */
    /* border: 0.25px solid #2e3e91; */


    --vs-border-color: #271d7a;
    --vs-border-width: 1px;
    --vs-border-style: solid;
    --vs-border-radius: 2px;





}
</style>