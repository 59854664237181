import { defineStore } from 'pinia'
import { API } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import awsconfig from '@/aws-exports';
// import AWS from "aws-sdk";
import { useS3Store } from '../aws/s3Store';


export const useAuthStore = defineStore('Auth', {
    state: () => ({
        current_user: null,
        current_db_user: null,
        current_environment: "production",
        front_end_user_partner_value: null,
        user_management_secret: null,
        users_groups: null,
        users_applications: [],
        default_application_path: null,
        switch_web_socket_url: null,
        TIME_OUT_WARNING_MINUTES: null,
        TIME_OUT_MINUTES: null,
        router_to_location_array: [],
        USER_IS_LOGGED_IN: null,
        cognito_tokens: null, //Users Cognito Tokens / Refresh / Acccess / ID
        user_id: null, // user_id used for cognito and db record
        email: null, // Users email / Used as username 
        phone_number: null,
        s3_bucket: null,
        user_pool_id: awsconfig.aws_user_pools_id,
        available_queues: [],
        current_user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        is_internal_user: false
    }),
    getters: {
        getCurrentUser(state) {
            return state.current_user;
        },
        getCurrentEnvironment(state) {
            return state.current_environment;
        },
        getUserManagementSecret(state) {
            return state.user_management_secret;
        },
        getUsersGroups(state) {
            return state.users_groups;
        },
        getUsersApplications(state) {
            return state.users_applications;
        },
        getCurrentDBUser(state) {
            return state.current_db_user;
        },
        getDefaultApplicationPath(state) {
            return state.default_application_path;
        },
        getSwitchWebSocketUrl(state) {
            return state.switch_web_socket_url;
        },
        getTimeOutWarningMinutes(state) {
            return state.TIME_OUT_WARNING_MINUTES;
        },
        getTimeOutMinutes(state) {
            return state.TIME_OUT_MINUTES;
        },
        getToLocationArray(state) {
            return state.router_to_location_array;
        },
        getUserAuthStatus(state) {
            return state.USER_IS_LOGGED_IN;
        },
        getIsInternalUser(state) {
            return state.is_internal_user
        }
    },
    actions: {
        async storeUserInfo(user_information) {
            //Setting Cognito User Values
            this.user_id = user_information.Username;

            for (let attribute of user_information.UserAttributes) {
                if (attribute.Name === "email") {
                    this.email = attribute.Value;
                }
                else if (attribute.Name === "phone_number") {
                    this.phone_number = attribute.Value;
                }
            }
            //Setting Cognito User Values
        },
        async setCurrentUser() {
            const api_name = "frontend";
            const path = "/get_secret";

            const userAuth = `Bearer ${this.cognito_tokens.id_token}`;

            var bodyJSON = {
                secret_id: "cognito"
            }

            const myInit = {
                body: bodyJSON,
                headers: {
                    Authorization: userAuth,
                    "Content-Type": "application/json"
                }
            }

            try {
                const response = await API.post(api_name, path, myInit);
                this.user_management_secret = response.data;
            }
            catch (error) {
                console.log("Do nothing for now");
            }

            try {
                await this.fetchUserDBInfo();
            } catch {
                return;
            }
        },
        setCurrentEnvironment(environment) {
            this.current_environment = environment;
        },
        async updateDefaultApplication(value) {
            if (!value) {
                return { error: true, message: "Unable to update starting application." };
            }

            const response = await API.post("psql", '/query', {
                headers: {
                    Authorization: this.cognito_tokens.id_token,
                    "Content-Type": "application/json"
                },
                body: {
                    store: "authStore",
                    query: "UPDATE/updateDefaultApplication",
                    values: [value, this.current_db_user.id]
                }
            });

            if (response.success) {
                await this.fetchUserDBInfo();
                return { error: false, message: 'Ok'};
            }
            else {
                console.error("Caught an Error in UpdateDefaultApplication", response);
                return { error: true, message: response}
            }
        },
        async getPasswordRequirements() {
            const CLIENT_ID = awsconfig.aws_user_pools_web_client_id;

            try {
                const get_password_information_response = await API.post("user_auth", '/get_password_information', {
                    headers: {
                        'Authorization': `Bearer ${this.cognito_tokens.id_token}`,
                        'Content-Type': 'application/json'
                    },
                    body: {
                        client_id: CLIENT_ID,
                        user_pool_id: awsconfig.aws_user_pools_id
                    },
                });

                const password_obj = get_password_information_response.data;
                if(get_password_information_response.success && password_obj && Object.keys(password_obj).length > 0) {
                    let password_string = "";

                    if(password_obj.min_length) {
                        const min_length = password_obj.min_length;
                        password_string += `<span>Minimum ${min_length} character(s) Length</span><br>`;
                    }
            
                    if(password_obj.lowercase) {
                        password_string += `<span>Contains at least 1 lowercase letter</span><br>`;
                    }
            
                    if(password_obj.numbers) {
                        password_string += `<span>Contains at least 1 number</span><br>`;
                    }
            
                    if(password_obj.symbols) {
                        password_string += `<span>Contains at least 1 special character</span><br>`;
                    }
            
                    if(password_obj.uppercase) {
                        password_string += `<span>Contains at least 1 uppercase letter</span><br>`;
                    }

                    password_string = password_string.trim();

                    return { error: false, requirements: password_string };

                } else {
                    return { error: true };
                }
            } catch {
                return { error: true };
            }
        },
        async getFirstApplication() {
            const sectionOrder = ["Workspace", "Analytics", "Billing", "Contact Center", "LES", "Switch Ops", "Tickets", "Voice", "Admin"];

            let firstApplicationRoute = null;
            let firstApplicationName = null;
            let firstApplicationID = null;

            for (let i = 0; i < sectionOrder.length; i++) {
                const section = sectionOrder[i];

                const appsInSection = this.users_applications.filter(app => app.application_section === section);

                for (let j = 0; j < appsInSection.length; j++) {
                    const app = appsInSection[j];

                    if (!app.route || app.route.trim() === "") continue;

                    if (!firstApplicationRoute || (app.name < firstApplicationName)) {
                        firstApplicationRoute = app.route;
                        firstApplicationName = app.name;
                        firstApplicationID = app.application_id;
                    }
                }

                if (firstApplicationRoute) break;
            }

            return { route: firstApplicationRoute, name: firstApplicationName, application_id: firstApplicationID };
        },
        async setDefaultApplicationPath() {
            var currentStartingApplicationID = this.current_db_user.starting_application_id;
            var firstApplication = await this.getFirstApplication();
            var id = firstApplication.application_id;
            if (!currentStartingApplicationID) {
                var updateResults = await this.updateDefaultApplication(id);
                if (updateResults.error) {
                    this.default_application_path = "/admin";
                } else {
                    this.default_application_path = firstApplication.route;
                }
            } else {
                //Validate that the id is still valid
                var foundObj = this.users_applications.find(x => x.application_id == currentStartingApplicationID);

                if (!foundObj) {
                    this.default_application_path = "/admin";
                    return;
                }

                if(Object.keys(foundObj).length == 0) {
                    var results = await this.updateDefaultApplication(id);
                    if (results.error) {
                        this.default_application_path = "/admin";
                    } else {
                        this.default_application_path = firstApplication.route;
                    }
                } else {
                    this.default_application_path = foundObj.route;
                }
            }
        },
        async fetchUsersGroups() {
            const user_response = await API.post("psql", '/query', {
                headers: {
                    Authorization: this.cognito_tokens.id_token,
                    "Content-Type": "application/json"
                },
                body: {
                    store: "authStore",
                    query: "GET/getUserByID",
                    values: [this.user_id]
                }
            });

            if (!user_response.success || user_response.data.rowCount == 0) {
                console.log("No groups or non-iterable groups for the user");
                return;
            }

            const user = user_response.data.rows[0];
            let users_groups = user.user_group.replaceAll(' ', '_');


            const response = await API.post("psql", '/query', {
                headers: {
                    Authorization: this.cognito_tokens.id_token,
                    "Content-Type": "application/json"
                },
                body: {
                    store: "groupManagementStore",
                    query: "GET/getFilteredGroups",
                    values: [users_groups]
                }
            });
            try {
                var users_applications = [];
                if (response.success && response.data.rowCount > 0) {
                    this.users_groups = response.data.rows.map(x => x.json_build_object);
                    for (var userGroup of this.users_groups) {
                        for (var appObject of userGroup.applications) {
                            var duplicateIndex = users_applications.findIndex(app => app.name === appObject.name && app.application_section === appObject.application_section);

                            //stacking apps and permissions
                            if (duplicateIndex === -1) {
                                appObject['allowed_permissions'] = userGroup.allowed_permissions.join(',') || "";
                                appObject.application_id = appObject.group_application_id;
                                users_applications.push({ ...appObject });
                            } else {
                                let existingPermissions = users_applications[duplicateIndex].allowed_permissions ? users_applications[duplicateIndex].allowed_permissions.split(',') : [];
                                let newPermissions = userGroup.allowed_permissions ? userGroup.allowed_permissions.split(',') : [];
                                let combinedPermissions = [...new Set([...existingPermissions, ...newPermissions])];
                                users_applications[duplicateIndex].allowed_permissions = combinedPermissions.join(',');
                            }
                        }
                    }
                    this.users_applications = users_applications;
                }
                else {
                    console.log("Error fetching user groups");
                }
            } catch(error) {
                console.log({error});
            }
        },
        async fetchUserDBInfo() {
            const response = await API.post("psql", '/query', {
                headers: {
                    Authorization: this.cognito_tokens.id_token,
                    "Content-Type": "application/json"
                },
                body: {
                    store: "authStore/GET",
                    query: "getUserByID",
                    values: [
                        this.user_id
                    ],
                    database: "lexconnect"
                }
            });
            this.current_db_user = response.data.rows[0];

            if(this.current_db_user.partner == "LogicomUSA") {
                this.is_internal_user = true;
            }

            const listPartners = /* GraphQL */ `
            query ListPartners(
                $filter: ModelPartnersFilterInput
                $limit: Int
                $nextToken: String
            ) {
                listPartners(filter: $filter, limit: $limit, nextToken: $nextToken) {
                items {
                    partner
                    switch_web_socket_url
                    name
                }
                nextToken
                startedAt
                __typename
                }
            }`;

            let partner_options = {
                query: listPartners,
                variables: {
                    filter: {
                        partner: {
                            eq: this.current_db_user.partner
                        }
                    }
                },
                authMode: GRAPHQL_AUTH_MODE.API_KEY
            }

            let partner_result = await API.graphql(partner_options);

            this.front_end_user_partner_value = partner_result.data.listPartners.items[0]?.name;
            this.switch_web_socket_url = partner_result.data.listPartners.items[0]?.switch_web_socket_url;
        },
        async validateAdminStatus(superuserOnly = false) {
            const hasSuperUser = this.users_groups.findIndex(x => x.name === 'SuperUser');

            if (superuserOnly) {
                if (hasSuperUser != -1) {
                    return true;
                }
            }

            var partner = this.current_db_user.partner;

            if (partner == "LogicomUSA" || hasSuperUser != -1) {
                return true;
            }

            return false;
        },
        async fetchSessionInformation() {
            let user_session_timeout_minutes = 0;

            try {
                const S3 = useS3Store();

                const s3_result = await S3.getObject({
                    Bucket: this.s3_bucket,
                    Key: `protected/${this.user_id}/session_timeout.json`
                });

                let timeout_object = JSON.parse(s3_result.Body.toString());

                if(timeout_object?.timeout_value > 60){
                    user_session_timeout_minutes = timeout_object.timeout_value;
                }
            } catch(e) {
                user_session_timeout_minutes = 0;
            }

            if (user_session_timeout_minutes && user_session_timeout_minutes > 60) {
                this.TIME_OUT_MINUTES = user_session_timeout_minutes;
                this.TIME_OUT_WARNING_MINUTES = user_session_timeout_minutes - 5;
            }
            else {
                const api_name = "frontend";
                const path = "/get_secret";

                const userAuth = `Bearer ${this.cognito_tokens.id_token}`;

                var bodyJSON = {
                    secret_id: "session_timeout"
                }

                const myInit = {
                    body: bodyJSON,
                    headers: {
                        Authorization: userAuth,
                        "Content-Type": "application/json"
                    }
                }

                try {
                    const response = await API.post(api_name, path, myInit);

                    if (response.data) {
                        this.TIME_OUT_WARNING_MINUTES = response.data.TIME_OUT_WARNING_MINUTES;
                        this.TIME_OUT_MINUTES = response.data.TIME_OUT_MINUTES;
                    }
                }
                catch (error) {
                    console.log("Do nothing for now");
                }
            }
        },
        async pushToLocationArray(location) {
            this.router_to_location_array.push(location);
        },
        async setLastLoggedInForUser() {
            const DATE_RIGHT_NOW = new Date(Date.now()).toISOString();
            await API.post("psql", '/query', {
                headers: {
                    Authorization: this.cognito_tokens.id_token,
                    "Content-Type": "application/json"
                },
                body: {
                    store: "authStore",
                    query: "UPDATE/updateLastLoggedIn",
                    values: [DATE_RIGHT_NOW, this.user_id]
                }
            });
        },
        async setUserAuthStatus(status) {
            this.USER_IS_LOGGED_IN = status;
        },
        async getAvailableQueues() {
            var response = null;
            var body = null;

            if (this.current_db_user.partner === "LogicomUSA") {
                body = {
                    store: "authStore/GET",
                    query: "getAllowedQueuesByGroup",
                    values: [
                        this.current_db_user.user_group.replaceAll("_", " ")
                    ],
                    database: "tickets"
                }
            }
            else {
                body = {
                    store: "authStore/GET",
                    query: "getAllowedQueuesByPartner",
                    values: [
                        this.current_db_user.partner
                    ],
                    database: "tickets"
                }
            }

            response = await API.post("psql", '/query', {
                headers: {
                    Authorization: this.cognito_tokens.id_token,
                    "Content-Type": "application/json"
                },
                body: body
            });

            if (response.success) {
                this.available_queues = response.data.rows;
            }
        },
        async bookmarkTicket(ticket_id) {
            var current_bookmarked_tickets = this.current_db_user.bookmarked_tickets || [];
            current_bookmarked_tickets.push(ticket_id);

            const response = await API.post("psql", '/query', {
                headers: {
                    Authorization: this.cognito_tokens.id_token,
                    "Content-Type": "application/json"
                },
                body: {
                    store: "authStore/UPDATE",
                    query: "updateUserBookmarkedTickets",
                    values: [
                        JSON.stringify(current_bookmarked_tickets),
                        this.user_id
                    ],
                    database: "lexconnect"
                }
            });

            if (response.success && response.data.rowCount > 0) {
                this.current_db_user.bookmarked_tickets = current_bookmarked_tickets;
            }

            return response;
        },
        async unBookmarkTicket(ticket_id) {
            var current_bookmarked_tickets = this.current_db_user.bookmarked_tickets || [];

            if (current_bookmarked_tickets.includes(ticket_id)) {
                current_bookmarked_tickets.splice(current_bookmarked_tickets.indexOf(ticket_id), 1);
            }

            const response = await API.post("psql", '/query', {
                headers: {
                    Authorization: this.cognito_tokens.id_token,
                    "Content-Type": "application/json"
                },
                body: {
                    store: "authStore/UPDATE",
                    query: "updateUserBookmarkedTickets",
                    values: [
                        JSON.stringify(current_bookmarked_tickets),
                        this.user_id
                    ],
                    database: "lexconnect"
                }
            });

            if (response.success && response.data.rowCount > 0) {
                this.current_db_user.bookmarked_tickets = current_bookmarked_tickets;
            }

            return response;
        },
        storeCognitoTokens(refresh_token, access_token, id_token) { //Used for Setting tokens froma  new session
            this.cognito_tokens = {
                refresh_token: refresh_token,
                access_token: access_token,
                id_token: id_token
            }
        },
        initializeCognitoTokens(last_auth_user, user_pool_id) { //Initializing current tokens from local Storage
            this.cognito_tokens = {
                refresh_token: localStorage.getItem(`${btoa(last_auth_user)}.${btoa(user_pool_id)}.refreshToken`),
                access_token: localStorage.getItem(`${btoa(last_auth_user)}.${btoa(user_pool_id)}.accessToken`),
                id_token: localStorage.getItem(`${btoa(last_auth_user)}.${btoa(user_pool_id)}.idToken`)
            }
        },
        setUserFilesBucket(s3_bucket) {
            this.s3_bucket = s3_bucket;
        },
        removeCognitoTokens(user_pool_id) {
            localStorage.removeItem(`${btoa(this.email)}.${btoa(user_pool_id)}.refreshToken`);
            localStorage.removeItem(`${btoa(this.email)}.${btoa(user_pool_id)}.accessToken`);
            localStorage.removeItem(`${btoa(this.email)}.${btoa(user_pool_id)}.idToken`);
        },
        updateAccessAndIdTokens(access_token, id_token, last_auth_user, user_pool_id) {
            localStorage.setItem(`${btoa(last_auth_user)}.${btoa(user_pool_id)}.accessToken`, access_token);
            localStorage.setItem(`${btoa(last_auth_user)}.${btoa(user_pool_id)}.idToken`, id_token);

            this.cognito_tokens.access_token = access_token;
            this.cognito_tokens.id_token = id_token
        }
    },
})