<template>
    <div>
        <label v-if="PROPS.required" class="inputLabel">{{ PROPS.label }} *</label>
        <label v-else class="inputLabel">{{ PROPS.label }}</label>
    </div>
    <div>
        <vue-multiselect 
            class="multiselect_component" 
            v-model="localValue" 
            :options="adjustedOptions" 
            :disabled="PROPS.disabled"
            :multiple="true"
            :close-on-select="false" 
            :clear-on-select="false" 
            label="label" 
            track-by="value" 
            :placeholder="placeholder"
            :optionHeight="28"
            :showLabels="false"
            :showPointer="false"
            @update:modelValue="handleValueChange"
            @close="handleClose"
        >
            <template #selection="{ values, isOpen }" v-if="PROPS.countOnly">
                <span class="selection" v-if="values.length" v-show="!isOpen">
                    {{ values.length }} options selected
                </span>
            </template>
            <template #selection="{ values, isOpen }" v-else-if="PROPS.no_overflow">
                <span class="selection restricted" v-if="values.length" v-show="!isOpen">
                    {{ values.map(x => x.label).join(', ') }}
                </span>
            </template>
            <!-- <template #tag="props" v-else>
                <div class="option_tag">{{ props.option.label }}</div>
            </template> -->
            <template #selection="{ values, isOpen }" v-else>
                <div class="selection unrestricted" v-if="values.length" v-show="!isOpen">
                    {{ values.map(x => x.label).join(', ') }}
                </div>
            </template>
            <template #option="props">
                <div class="optionLabel">
                    <span class="optionText restricted">{{ props.option.label }}</span>
                    <span class="dropDownMultiCheckbox">
                        <CheckBoxComponent
                            v-bind:required="false"
                            :modelValue="props.option.selected"
                        />
                    </span>
                </div>
            </template>
            <template #caret>
                <span class="multiselect__select selectToggle">
                    <img style="width: 20px;" :src="require(`@/assets/icons/caret_closed_dkblue.png`)"/>
                </span>
            </template>
            <template #beforeList v-if="selectAll">
                <div class="optionLabel" @click="toggleAll">
                    <span class="optionText">{{ allSelected ? "Deselect All": "Select All" }}</span>
                    <span class="dropDownMultiCheckbox">
                        <CheckBoxComponent
                            v-bind:required="false"
                            :modelValue="allSelected"
                        />
                    </span>
                </div>
            </template>
        </vue-multiselect>
    </div>
</template>



<script setup>
import { defineProps, computed, defineEmits } from "vue";
import CheckBoxComponent from "@/components/base_components/CheckboxComponent.vue";

const emit = defineEmits(["update:modelValue", "close-select", "update:localValue"]);

//Props
const PROPS = defineProps({
    options: {
        type: Array,
        default: () => []
    },
    name: String,
    disabled: {
        type: Boolean,
        default: false,
    },
    countOnly: {
        type: Boolean,
        default: false,
    },
    no_overflow: {
        type: Boolean,
        default: false
    },
    reduceFunction: {
        type: Function,
        default: (option) => ({ label: option.label, value: option.value })
    },
    clearable: {
        type: Boolean,
        default: false,
    },
    searchable: {
        type: Boolean,
        default: false,
    },
    filterable: {
        type: Boolean,
        default: true,
    },
    placeholder: {
        type: String,
        default: ""
    },
    label: {
        type: String,
        default: ""
    },
    required: { //Add Required Visual to Inputs Label
        type: Boolean,
        default: false
    },
    modelValue: {
        type: Array,
        default: () => []
    },
    sorted: {
        type: Boolean,
        default: true
    },
    selectAll: {
        type: Boolean,
        default: false
    }

});

//Computed
const adjustedOptions = computed(() => {
    if (PROPS.options) {
        var new_options = [...PROPS.options];
        var mapped_options = [];
        if(PROPS.sorted) {
            var sorted_options = new_options.sort((a, b) => a.label.localeCompare(b.label));
            mapped_options = sorted_options.map(x => ({...x, "selected": currentlySelected(x), type: "option"}));
        } else {
            mapped_options = new_options.map(x => ({...x, "selected": currentlySelected(x), type: "option"}));
        }

        return mapped_options;
    }
    return PROPS.options;
});

const localValue = computed({
    get() {
        var localOptions = [...PROPS.modelValue];
        return localOptions.sort((a, b) => a.label.localeCompare(b.label));
      },
      set(value) {
        emit('update:localValue', value);
      }
});

const allSelected = computed(() => {
    return PROPS.modelValue.length == PROPS.options.length;
});


//Functions
function currentlySelected(option) {
    if(localValue.value.length > 0 && localValue.value.findIndex(x => x.value == option.value && x.label == option.label) != -1) {
        return true;
    } else {
        return false;
    }
}

function toggleAll() {
    var new_toggle_val = !allSelected.value;

    if(new_toggle_val) {
        emit("update:modelValue", [...PROPS.options]); 
    } else {
        emit("update:modelValue", []); 
    }
}

function handleValueChange(value) {
    emit("update:modelValue", value); 
}

function handleClose() {
    emit('close-select');
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
.inputLabel {
    font-size: 9pt;
    font-weight: 600;
    color: #271d7a;
    /* padding-bottom: 5px; */
}

.optionText {
    margin-left: 8px;
}

.dropDownMultiCheckbox {
    margin-top: 6px;
    margin-right: 5px;
    width: 20px;
}

.optionLabel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 25px;
}

.multiselect.multiselect_component {
    background-color: #FFF;
    font-size: 12px;
    color: #271d7a;
    min-height: 28px;
}

.restricted {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    margin-top: 2px;
}

.selection {
    max-width: 85%;
    font-size: 12px;
    padding-right: 10px;
}

.selectToggle::before {
    content: none;
}

.selectToggle {
    /* padding-right: 0px; */
    width: 34px;
}


:deep(.multiselect.multiselect_component .multiselect__tags),
:deep(.multiselect.multiselect_component .multiselect__select) {
    min-height: 28px;
    /* height: 28px; */
}

:deep(.multiselect.multiselect_component .multiselect__tags) {
    background-color: white;
    font-size: 12px;
    color: #271d7a;
    outline: 1px solid #2e3e91;
    /* border-bottom: 0px; */
    border-radius: 2px;
    padding: 0px 0px 0px 8px;
}

:deep(.multiselect.multiselect_component .multiselect__content-wrapper) {
    background-color: white;
    font-size: 12px;
    color: #271d7a;
    outline: 1px solid #2e3e91;
    border-radius: 2px;
    /* padding: 0px 0px 0px 8px; */
}

:deep(.multiselect.multiselect_component .multiselect__content) {
    width: 100%;
}

:deep(.multiselect.multiselect_component .multiselect__input) {
    background-color: white;
    font-size: 12px;
    color: #271d7a;
}



:deep(.multiselect.multiselect_component .multiselect__element:hover) {
    background: #eff4f9;
}

:deep(.multiselect.multiselect_component > .multiselect__tags > .multiselect__placeholder) {
    color: #271d7a !important;
    margin-bottom: 0px;
    margin-top: 2px;
}

:deep(.multiselect.multiselect_component .multiselect__select) {
    height: 28px;
}

:deep(.multiselect.multiselect_component .multiselect__input) {
    max-width: 80%;
}

:deep(.multiselect.multiselect_component .multiselect__element), 
:deep(.multiselect.multiselect_component .multiselect__option),
:deep(.multiselect.multiselect_component .multiselect__option--selected) {
    min-height: 25px;
    height: 25px;
    padding: 0px;
}

:deep(.multiselect.multiselect_component .multiselect__option--selected) {
    background: #FFF;
    color:#271d7a;
    font-weight: 400;
}

:deep(.multiselect--disabled.multiselect.multiselect_component), :deep(.multiselect--disabled.multiselect_component .multiselect__select) {
    background: #FFF;
    color: #271d7a;
    opacity: 1;
    box-sizing: inherit;
    height: 28px;
    border-bottom: 1px solid #271d7a;
}

:deep(.multiselect--disabled.multiselect_component .multiselect__tags) {
    background: #FFF;
}

.option_tag {
    position: relative;
    display: inline-block;
    padding: 4px 6px 4px 10px;
    border: 1px solid black;
    color: #271d7a;
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
}
</style>